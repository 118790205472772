import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import SealSpinner from '../../components/seal-spinner/SealSpinner.js';
import ProvisioningProgress from '../../components/provisioning-progress/ProvisioningProgress.js';

const ProvisionMailserver = ({mailserver}) => {
  if (!mailserver.domain)
    return <Redirect to="/" />;

  if (mailserver.state === 'provisioned') {
    clearTimeout(mailserver.timeout);
    return <Redirect to="/mailservers/provisioned" />;
  }

  return <div className="ProvisionMailserver">
    <h3>Configuring {`mail.${mailserver.domain}`}</h3>
    <SealSpinner />
    <ProvisioningProgress />
  </div>;
};

const mapStateToProps = state => ({
  mailserver: state.mailserver
});

export default connect(mapStateToProps, null)(ProvisionMailserver);
