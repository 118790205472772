import React from 'react';
import { connect } from 'react-redux';

const ProvisioningProgress = ({mailserver}) => {
  return <div className="ProvisioningProgress">
    <div className="progress-amount">{mailserver.progress && `${mailserver.progress}%`}</div>
  </div>
};

const mapStateToProps = (state, props) => ({
  mailserver: state.mailserver
});

export default connect(mapStateToProps, null)(ProvisioningProgress);
