/* globals Stripe */

import API from './lib/API.js';

const requestStart = requestName => ({
  type: `REQUEST_START_${requestName}`
});

export const updateAttribute = (model, attribute, value) => ({
  type: `UPDATE_${model.toUpperCase()}`,
  attribute,
  value
});

const requestCheckMailServerSuccessul = body => ({
  type: 'CHECK_MAIL_SERVER_EXISTS_SUCCESSFUL',
  response: body
});

const requestCheckMailServerFailed = body => ({
  type: 'CHECK_MAIL_SERVER_EXISTS_FAILED',
  response: body
});

export const checkMailServerExists = domain => dispatch => {
  dispatch(requestStart('CHECK_MAIL_SERVER_EXISTS'));

  return API.checkMailServerExists(domain).then(
    (response) => {
      if (response.status === 200)
        response.json().then(body => {
          dispatch(requestCheckMailServerSuccessul(body));
        });
      else
        response.json().then(body => {
          dispatch(requestCheckMailServerFailed(body));
        });
    }
  );
};

export const updateSelectedProduct = productId => ({
  type: 'UPDATE_SELECTED_PRODUCT',
  productId
});

const requestChargeCard = () => ({
  type: 'REQUEST_CHARGE_CARD'
});

export const chargeCard = (stripeCard, mailserver, productId) => dispatch => {
  dispatch(requestChargeCard());
  Stripe.setPublishableKey(process.env.REACT_APP_STRIPE_API_KEY);
  return Stripe
    .card
    .createToken(stripeCard, (status, response) => {
      if (response.error) {
        dispatch(stripeTokenError(response.error));
      }
      else {
        dispatch(receivedStripeToken(response.id));
        API.chargeCard(response.id, mailserver, productId).then(
          (response) => {
            if (response.status === 200) {
              dispatch(cardChargedSuccessfully());
              dispatch(createServer(mailserver));
            }
            else {
              response.json().then(body => {
                if (body.error === 'Subscription already exists') {
                  dispatch(cardChargedSuccessfully());
                  dispatch(createServer(mailserver));
                }
                else {
                  dispatch(chargingCardFailed(body.error));
                }
              });
            }
          }
        );
      }
    })
}

const stripeTokenError = error => ({
  type: 'STRIPE_TOKEN_ERROR',
  error: error
});

const receivedStripeToken = token => dispatch => ({
  type: 'RECEIVED_STRIPE_TOKEN',
  stripeToken: token
});

const cardChargedSuccessfully = () => ({
  type: 'CHARGED_CARD'
});

const chargingCardFailed = error => ({
  type: 'CHARGING_CARD_FAILED',
  error: error
});

export const createServer = mailserver => dispatch => {
  dispatch(requestCreateServer());

  API.createServer(mailserver).then(
    (response) => {
      if (response.status === 200)
        response.json().then(body => {
          dispatch(requestCreateServerSuccessful(body));
        });
      else
        response.json().then(body => {
          dispatch(requestCreateServerFailed(body));
        })
    }
  );
};

const requestCreateServer = () => ({
  type: 'REQUEST_CREATE_SERVER'
});

const requestCreateServerSuccessful = response => ({
  type: 'REQUEST_CREATE_SERVER_SUCCESSFUL',
  response
});

const requestCreateServerFailed = response => ({
  type: 'REQUEST_CREATE_SERVER_FAILED',
  response
});

const requestProvisioningProgress = () => ({
  type: 'REQUEST_PROVISIONING_PROGRESS'
});

const requestProvisioningProgressSuccessful = response => ({
  type: 'REQUEST_PROVISIONING_PROGRESS_SUCCESSFUL',
  response
});

const requestProvisioningProgressFailed = () => ({
  type: 'REQUEST_PROVISIONING_PROGRESS_FAILED'
});

export const getProvisioningProgress = (domain) => dispatch => {
  let timeout = setTimeout(() => {
    dispatch(requestProvisioningProgress());
    API.getProvisioningProgress(domain).then(
      response => {
        if (response.status === 200)
          response.json().then(
            body => {
              dispatch(requestProvisioningProgressSuccessful({timeout: timeout, ...body}));
            }
          )
        else
          dispatch(requestProvisioningProgressFailed());
      }
    );

    timeout = setTimeout(
      () => dispatch(getProvisioningProgress(domain)),
      2000
    )
  }, 2000)
};

const requestProvisionMailserver = () => ({
  type: 'REQUEST_PROVISION_MAILSERVER'
});

const requestProvisionMailserverSuccessful = response => ({
  type: 'REQUEST_PROVISION_MAILSERVER_SUCCESSFUL',
  response
});

const requestProvisionMailserverFailed = response => ({
  type: 'REQUEST_PROVISION_MAILSERVER_FAILED',
  response
});

export const provisionMailserver = (mailserver) => dispatch => {
  dispatch(requestProvisionMailserver());

  API.provisionMailserver(mailserver).then(
    response => {
      response.json().then(
        body => {
          if (response.status === 200) {
            dispatch(requestProvisionMailserverSuccessful(body));
            dispatch(getProvisioningProgress(mailserver.domain));
          }
          else
            dispatch(requestProvisionMailserverFailed(body));
        }
      )
    }
  )
};
