export const spinner = (state = {}, action) => {
  switch (action.type) {
    case 'REQUEST_CHARGE_CARD':
      return {spinning: true};
    case 'REQUEST_CREATE_SERVER_SUCCESSFUL':
      return {spinning: false};
    default:
      return {spinning: false}
  }
};

export const mailserver = (
  state = {
    domain: '',
    username: '',
    password: ''
  },
  action
) => {
  switch (action.type) {
    case 'UPDATE_MAILSERVER':
      return {...state, [action.attribute]: action.value};
    case 'CHECK_MAIL_SERVER_EXISTS_SUCCESSFUL':
      return {...state, exists: action.response.exists, ...action.response.mailserver, newSubscription: !action.response.exists};
    case 'CHECK_MAIL_SERVER_EXISTS_FAILED':
      return {...state, error: action.response.error};
    case 'REQUEST_CREATE_SERVER_SUCCESSFUL':
      return {...state, ...action.response, state: 'created'}
    case 'REQUEST_CREATE_SERVER_FAILED':
      return {...state, ...action.response.mailserver, state: 'failed'}
    case 'REQUEST_PROVISIONING_PROGRESS_SUCCESSFUL':
      const mailserverState = action.response.progress >= 100 ?
                    'provisioned' :
                    'provisioning';

      return {...state, ...action.response, state: mailserverState}
    case 'REQUEST_PROVISION_MAILSERVER_SUCCESSFUL':
      return {...state, ...action.response, state: 'provisioning'}
    case 'REQUEST_PROVISION_MAILSERVER_FAILED':
      if (action.response && !action.response.dns_ready)
        return {...state, ...action.response, state: 'invalid_dns'}
      else
        return {...state, ...action.response, state: 'error'}
    default:
      return state;
  }
};

export const product = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_SELECTED_PRODUCT':
      return {id: action.productId};
    default:
      return state;
  }
};

export const stripeCard = (
  state = {
    card: {
      number: '',
      exp_month: '',
      exp_year: '',
      cvc: '',
    }
  },
  action
) => {
  switch (action.type) {
    case 'UPDATE_STRIPECARD':
      return {...state, card: {...state.card, [action.attribute]: action.value}}
    case 'RECEIVED_STRIPE_TOKEN':
      return {...state, token: action.stripeToken}
    case 'CHARGED_CARD':
      return {...state, charged: true}
    case 'CHARGING_CARD_FAILED':
      return {...state, charged: false, cardError: action.error}
    case 'STRIPE_TOKEN_ERROR':
      return {...state, charged: false, cardError: action.error}
    default:
      return state;
  }
};
