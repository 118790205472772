import React from 'react';

import CheckoutForm from '../pages/CheckoutForm.js';

const NewSubscription = () => (
  <div className="NewSubscription">
    <CheckoutForm />
  </div>
);

export default NewSubscription;
