import thunkMiddleware from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import { spinner, mailserver, stripeCard, product } from './reducers.js';

const store = createStore(
  combineReducers({
    spinner,
    mailserver,
    stripeCard,
    product
  }),
  composeWithDevTools(
    applyMiddleware(thunkMiddleware),
  )
);
export default store;
