import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import './SetupMailClient.css';

const SetupMailClient = ({mailserver}) => {
  if (!mailserver.domain)
    return <Redirect to="/" />;

  return <div className="SetupMailClient">
    <h2 className="ready-to-use">Your private mail server is ready to use!</h2>
    <div className="mailserver-config"><span className="config-name">Account type: </span><span className="config-value">IMAP</span></div>
    <div className="mailserver-config"><span className="config-name">Email address: </span><span id="email-address" className=" config-value">{`${mailserver.username}@${mailserver.domain}`}</span></div>
    <div className="mailserver-config"><span className="config-name">Incoming Mail Server: </span><span id="incoming-mail-server" className="config-value">{`mail.${mailserver.domain}`}</span></div>
    <div className="mailserver-config"><span className="config-name">Outgoing Mail Server: </span><span id="outgoing-mail-server" className="config-value">{`mail.${mailserver.domain}`}</span></div>
    <div className="mailserver-config"><span className="config-name">User Name: </span><span id="username" className="config-value">{`${mailserver.username}@${mailserver.domain}`}</span></div>
    <div className="instruction-videos">
      <div className="android">
        <h4>How to add your email account on Android</h4>
        <video className="android-instructions" src="https://res.cloudinary.com/deainpezt/video/upload/v1480042510/android-email-account-setup_macwqt.mp4" controls></video>
      </div>
      <div className="mac-mail-client">
        <h4>How to add your email account to your Mail client</h4>
        <video className="mail-client-instructions" src="https://res.cloudinary.com/deainpezt/video/upload/v1480098360/macos-add-email-account_mxodqv.mp4" controls></video>
      </div>
    </div>
  </div>
};

const mapStateToProps = state => ({
  mailserver: state.mailserver
});

export default connect(mapStateToProps, null)(SetupMailClient);
