import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import FloatingLabelInput from '../components/floating-label-input/FloatingLabelInput.js';
import Button from '../components/button/Button.js';

import { checkMailServerExists } from '../actions.js';

import './Home.css';

class Home extends Component {
  render = () => {
    const { mailserver, checkMailServerExists } = this.props;

    if (mailserver.exists)
      return <Redirect to="/mailservers/new" />;

    if (mailserver.newSubscription)
      this.props.history.push('/subscribe');

    return (
      <div className="Home">
        <h1 className="heading">SealMail</h1>
        <div className="logo">
          <div className="envelope">
            <div className="baby-seal"></div>
          </div>
        </div>
        <div className="slogan">Your Mail. Sealed.</div>
        <h1 className="heading">Seal Your Mail</h1>
        <h3 className="callout">Ever wanted your own private mail server?</h3>
        <div>Get one in 4 easy steps:</div>
        <div className="step-container flex-column">
          <div className="flex-row">
            <div className="step-number">1</div>
            <div className="flex-column">
              <div className="step-text">Enter your domain.</div>
              <FloatingLabelInput
                className="domain-input"
                placeholder="your-name.com"
                label="domain"
                type="text"
                model="mailserver"
                attribute="domain"
                value={mailserver.domain}
              />
            </div>
          </div>
        </div>
        <div className="step-container flex-column">
          <div className="flex-row">
            <div className="step-number">2</div>
            <div className="flex-column">
              <div className="step-text">
                In less than 60 seconds, we'll spin up a private mailserver in the cloud, just for you.
              </div>
              <br/>
              <div className="step-text">
                Your private mailserver comes with 25GB of storage space and you can create as many email accounts / addresses on it as you like!
              </div>
              <br/>
              <div className="step-text">
                To get your mail routed correctly, you'll have to add some new DNS records at your domain registrar or DNS provider to point to the new mailserver's IP address.
              </div>
              <br/>
              <div className="step-text">
                Your mailserver's IP address will be provided after you progress to the next step.
              </div>
            </div>
          </div>
        </div>
        <div className="step-container flex-column">
          <div className="flex-row">
            <div className="step-number">3</div>
            <div className="flex-column">
              <div className="step-text">Create your email account on your very own private mailserver</div>
              <FloatingLabelInput
                className="email-username"
                placeholder="somebody"
                label="username"
                type="text"
                model="mailserver"
                attribute="username"
                value={mailserver.username}
              />
              <FloatingLabelInput
                className="email-password"
                type="password"
                placeholder="password"
                label="password"
                model="mailserver"
                attribute="password"
                value={mailserver.password}
              />
              <div className="step-text">If your account becomes inaccessible for any reason, simply login to the server itself via SSH and reset the password there. No more <a target="_blank" rel="noopener noreferrer" href="https://productforums.google.com/forum/#!topic/gmail/HjW2Pj5Jqq4">endless Gmail / Yahoo / Webmail provider password reset loops</a></div>
            </div>
          </div>
        </div>
        <div className="step-container flex-column">
          <div className="flex-row">
            <div className="step-number">4</div>
            <div className="step-text">
              {
                `Setup your mail account in your email client${mailserver.domain && mailserver.username ? ' to receive email at ' + mailserver.username + '@' + mailserver.domain : ''}.`
              }
            </div>
          </div>
          <video src="https://res.cloudinary.com/deainpezt/video/upload/v1480042510/android-email-account-setup_macwqt.mp4" controls></video>
        </div>
        <br/>
        <div className="action-copy">And in less than 10 minutes, you are ready to send and receive email from your own private mailserver. Nobody except you can read or access your email. Not even Google.</div>
        <Button text="Create My MailServer" onClick={() => checkMailServerExists(mailserver.domain)} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  mailserver: state.mailserver || {}
});

const mapDispatchToProps = (dispatch, props) => ({
  checkMailServerExists: domain => dispatch(checkMailServerExists(domain))
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
