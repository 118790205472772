import React from 'react';
import { connect } from 'react-redux';

import { updateAttribute } from '../../actions.js';

import './FloatingLabelInput.css';

const FloatingLabelInput = (props) => (
  <label className={`FloatingLabelInput ${props.className ? props.className : ''}`}>
    <input
      type={props.type || 'text'}
      placeholder={props.placeholder}
      maxLength={props.maxlength || 30}
      value={props.value}
      onChange={e =>
        props.updateAttribute(
          props.model, props.attribute, e.target.value
        )
      }
    />
    <div>{props.label}</div>
  </label>
);

const mapDispatchToProps = (dispatch, props) => ({
  updateAttribute: (model, attribute, value) =>
    dispatch(updateAttribute(model, attribute, value))
});

export default connect(null, mapDispatchToProps)(FloatingLabelInput);
