import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import SealSpinner from '../../components/seal-spinner/SealSpinner.js';
import Button from '../../components/button/Button.js';
import { provisionMailserver } from '../../actions.js';

import './NewMailserver.css';

const NewMailserver = ({
  mailserver,
  provisionMailserver
}) => {
  if (!mailserver.domain)
    return <Redirect to="/" />;

  let heading;

  switch (mailserver.state) {
    case 'created':
      heading = <Fragment>
        <h3>
          {`mail.${mailserver.domain} is alive!`}
        </h3>
        <h4>
          Create the DNS records below to continue
        </h4>
      </Fragment>
      ;
      break;
    case 'invalid_dns':
      heading = <Fragment>
        <h3>
          {`DNS records for mail.${mailserver.domain} are incorrect`}
        </h3>
        <div>{mailserver.dns_check.dns_a}</div>
        <div>{mailserver.dns_check.dns_mx}</div>
        <h4>
          Create the DNS records below to continue
        </h4>
      </Fragment>;
      break;
    case 'provisioning':
      return <Redirect to="/mailservers/provision" />;
    case 'provisioned':
      return <Redirect to="/mailservers/provisioned" />;
    default:
      heading = <h3>{`Creating mail.${mailserver.domain}...`}</h3>;
  }

  const onProvisionMailserverClicked = (e) => (
    provisionMailserver(mailserver)
  );

  return <div className="NewMailserver">
    <Fragment>
      {heading}
      {
        (mailserver.state === 'created' || mailserver.state === 'invalid_dns') &&
        <div className="dns-details">
          <div className="dns-instructions">
            <h2>A Record</h2>
            <div className="row">
              <div className="values">
                <div className="column-name">Record Type:</div>
                <div className="instruction">A</div>
              </div>
              <div className="values">
                <div className="column-name">Hostname:</div>
                <div className="instruction">mail</div>
              </div>
              <div className="values">
                <div className="column-name">Destination:</div>
                <div className="instruction">{mailserver.ipv4_address}</div>
              </div>
            </div>
            <h2>MX Record</h2>
            <div className="row">
              <div className="values">
                <div className="column-name">Record Type:</div>
                <div className="instruction">MX</div>
              </div>
              <div className="values">
                <div className="column-name">Hostname:</div>
                <div className="instruction">{`${mailserver.domain}`}</div>
              </div>
              <div className="values">
                <div className="column-name">Mail Server Host:</div>
                <div className="instruction">{`mail.${mailserver.domain}`}</div>
              </div>
              <div className="values">
                <div className="column-name">Priority:</div>
                <div className="instruction">1</div>
              </div>
            </div>
          </div>
          <Button
            onClick={onProvisionMailserverClicked}
            text="Continue server setup"
          />
        </div> ||
        <SealSpinner />
      }
    </Fragment>
  </div>
};

const mapStateToProps = (state, props) => ({
  mailserver: state.mailserver
});

const mapDispatchToProps = (dispatch, props) => ({
  provisionMailserver: mailserver =>
    dispatch(provisionMailserver(mailserver))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewMailserver);
