const baseURI = `${process.env.REACT_APP_HTTP_SCHEME}://${process.env.REACT_APP_API_HOST}`

const API = {
  checkMailServerExists: domain => (
    fetch(`${baseURI}/v1/mailservers/exists?domain=${domain}`, {
      method: "GET",
      headers: {"Content-Type": "application/json", "Accept": "application/json"},
    })
  ),
  chargeCard: (token, mailserver, productId) => (
    fetch(`${baseURI}/v1/subscriptions`, {
      method: "POST",
      headers: {"Content-Type": "application/json", "Accept": "application/json"},
      body: JSON.stringify({
        stripe_token: token,
        mailserver: mailserver,
        product_id: productId
      })
    })
  ),
  createServer: mailserver => (
    fetch(`${baseURI}/v1/mailservers`, {
      method: "POST",
      headers: {"Content-Type": "application/json", "Accept": "application/json"},
      body: JSON.stringify({mailserver: {...mailserver}})
    })
  ),
  provisionMailserver: mailserver => {
    const params = Object.keys(mailserver).reduce((reduced, key) => {
      reduced.push(`${key}=${mailserver[key]}`);

      return reduced;
    }, []);

    return fetch(`${baseURI}/v1/mailservers/provision?${params.join('&')}`, {
      method: "GET",
      headers: {"Content-Type": "application/json", "Accept": "application/json"},
    })
  },
  getProvisioningProgress: domain => (
    fetch(`${baseURI}/v1/mailservers/progress?domain=${domain}`, {
      method: "GET",
      headers: {"Content-Type": "application/json", "Accept": "application/json"},
    })
  )
};

export default API;
