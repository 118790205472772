import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import SealSpinner from '../components/seal-spinner/SealSpinner.js';
import FloatingLabelInput from '../components/floating-label-input/FloatingLabelInput.js';
import Button from '../components/button/Button.js';

import { updateSelectedProduct, chargeCard } from '../actions.js';

import './CheckoutForm.css';

class CheckoutForm extends Component {
  render() {
    const {
      spinner,
      mailserver,
      stripeCard,
      stripeCard: card,
      updateSelectedProduct,
    } = this.props;

    if (!mailserver.domain)
      return <Redirect to="/" />;

    if (stripeCard.charged)
      return <Redirect to="/mailservers/new" />;

    if (spinner.spinning)
      return <SealSpinner />;

    return (
      <div className="CheckoutForm">
        <h1>Choose your storage capacity option</h1>
        <div className="storage-options">
          <label className="storage-option" htmlFor="25gb">
            <input
              id="25gb"
              type="radio"
              name="capacity"
              value="plan_EG807nICLfdpAN"
              onChange={e => updateSelectedProduct(e.target.value) }
            />
            <div className="option"><span>25</span><span>GB</span></div>
            <div className="price">$10 / mo</div>
          </label>
          <label className="storage-option" htmlFor="100gb">
            <input
              id="100gb"
              type="radio"
              name="capacity"
              value="plan_ENwm9yBlS0CI70"
              onChange={e => updateSelectedProduct(e.target.value) }
            />
            <div className="option"><span>100</span><span>GB</span></div>
            <div className="price">$30 / mo</div>
          </label>
          <label className="storage-option" htmlFor="500gb">
            <input
              id="500gb"
              type="radio"
              name="capacity"
              value="plan_ENwmOUcDoQ9XzE"
              onChange={e => updateSelectedProduct(e.target.value) }
            />
            <div className="option"><span>500</span><span>GB</span></div>
            <div className="price">$100 / mo</div>
          </label>
          <label className="storage-option" htmlFor="1000gb">
            <input
              id="1000gb"
              type="radio"
              name="capacity"
              value="plan_ENwnZtvYC5xrfe"
              onChange={e => updateSelectedProduct(e.target.value) }
            />
            <div className="option"><span>1000</span><span>GB</span></div>
            <div className="price">$200 / mo</div>
          </label>
        </div>
        <h3 className="billing-info">Billing Information</h3>
        <div className="credit-card-fields">
          {
            stripeCard.cardError && <div className="card-error">There was an error processing your credit card, please try a different card</div>
          }
          <FloatingLabelInput
            className="cc-number"
            label="Card Number"
            type="tel"
            placeholder="4242 4242 4242 4242"
            maxlength="16"
            model="stripeCard"
            attribute="number"
            value={card.number}
          />
          <div className="cc-info-row">
            <FloatingLabelInput
              className="cc-exp-month"
              label="MM"
              type="tel"
              placeholder="MM"
              maxlength="2"
              model="stripeCard"
              attribute="exp_month"
              value={card.exp_month}
            />
            <FloatingLabelInput
              className="cc-exp-year"
              label="YY"
              type="tel"
              placeholder="YY"
              maxlength="2"
              model="stripeCard"
              attribute="exp_year"
              value={card.exp_year}
            />
            <FloatingLabelInput
              className="cc-cvc"
              label="CVC"
              type="tel"
              placeholder="123"
              maxlength="3"
              model="stripeCard"
              attribute="cvc"
              value={card.cvc}
            />
          </div>
        </div>
        <Button
          text="Submit"
          onClick={this.submit}
        />
      </div>
    );
  }

  submit = () => {
    const { stripeCard, mailserver, product, chargeCard } = this.props;

    chargeCard(stripeCard.card, mailserver, product.id);
  }
}

const mapStateToProps = state => ({
  spinner: state.spinner,
  stripeCard: state.stripeCard,
  mailserver: state.mailserver,
  product: state.product,
  cardError: state.cardError
});

const mapDispatchToProps = (dispatch, props) => ({
  updateSelectedProduct: productId => dispatch(updateSelectedProduct(productId)),
  chargeCard: (stripeCard, mailserver, productId) => dispatch(chargeCard(stripeCard, mailserver, productId))
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);
