import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Home from '../pages/Home.js';
import NewSubscription from '../pages/NewSubscription.js';
import NewMailserver from '../pages/new-mailserver/NewMailserver.js';
import ProvisionMailserver from '../pages/provision-mailserver/ProvisionMailserver.js';
import SetupMailClient from '../pages/setup-mail-client/SetupMailClient.js';

const Routes = () => (
  <Router>
    <div>
      <Route path="/" exact component={Home} />
      <Route path="/subscribe" exact component={NewSubscription} />
      <Route path="/mailservers/new" exact component={NewMailserver} />
      <Route path="/mailservers/provision" exact component={ProvisionMailserver} />
      <Route path="/mailservers/provisioned" exact component={SetupMailClient} />
    </div>
  </Router>
)

export default Routes;
